.cc-manage-communication-body {
  .cc-message-template-container {
    @apply grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 gap-4;
    .cc-message-template-left-preview {
      @apply col-span-1 lg:col-span-2 xl:col-span-3;
    }
    .cc-message-template-left-un-preview {
      @apply col-span-1 lg:col-span-3 xl:col-span-4;
    }
    .cc-field-preview {
      @apply hidden md:flex #{!important};
    }
    .cc-message-template-left {
      .cc-field-group {
        @apply space-y-4;
        .cc-form-cols-1 {
          .cc-text-email {
            .k-content {
              > p {
                @apply text-black #{!important};
              }
            }
          }
          @apply gap-y-4;
        }
        .cc-input-group-cols-3 {
          @apply pt-8;
        }
      }
      .k-tabstrip {
        .k-tabstrip-items {
          @apply overflow-hidden #{!important};
        }
        .k-content {
          @apply px-0 py-1 border-none text-black;
        }
      }
    }
    .cc-message-template-right {
      @apply hidden items-center justify-center md:flex;
    }
    .cc-message-template-right-un-preview {
      @apply hidden;
    }
  }
  .cc-checkbox-group-message {
    @apply flex lg:space-x-4 md:space-x-8 md:items-center md:flex-row flex-col items-start gap-y-2.5;
    .cc-field {
      margin-left: 0px;
    }
  }
  .cc-editor-subject {
    @apply rounded;
  }
}

.k-editor-window .k-window-actions {
  @apply m-0;
}
