.cc-app-notification-item {
  @apply w-full px-3 py-2 #{!important};

  .cc-app-notification-item-title {
    @apply leading-5;
  }
  .cc-app-notification-item-descriptions {
    @apply flex flex-col justify-start items-start;
    @apply space-y-1;
  }

  .cc-app-notification-item-descriptions:only-child {
    @apply leading-5;
  }

  & > .k-notification-wrap {
    & > .k-i-close {
      @apply transition-all ease-in-out;
      @apply hover:opacity-50;
    }
    & > .k-i-none::before {
      display: block;
      content: "\e655";
    }
  }
}
