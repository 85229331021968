.cc-auto-complete {
  @apply flex items-end;
  .cc-input-picker-field {
    @apply flex-grow rounded-r-none;
  }
  .cc-input-picker-button {
    @apply flex-grow-0 p-0 rounded-l-none border-l-0 border-border;
    height: 28px !important;
    color: var(--accent) !important;
    background-color: inherit !important;
    width: 39px !important;
  }
}
