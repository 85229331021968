.cc-contact-picker-search {
  .k-popup {
    @apply rounded-none;
    max-height: 200px;
    // width: fit-content; // remove comment if you want set full width popup
    .k-list {
      @apply overflow-auto bg-secondary;
      .k-table-header {
        width: 800px;
        @apply bg-secondary;
        .cc-search-header {
          @apply flex w-full;
          & > div {
            @apply p-2;
          }
        }
      }
      .k-list-content {
        width: 810px;
        @apply bg-component-bg;
        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
          li {
            display: flex;
            border-bottom: 1px solid #ccc;
            padding: 0;
            .cc-search-item {
              @apply flex w-full;
              & > div {
                @apply p-2;
              }
            }
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}
