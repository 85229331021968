.cc-location-picker-search {
  .k-popup {
    min-width: 250px;
    @apply py-0 overflow-x-hidden  #{!important};
    & > .k-list-scroller {
      @apply overflow-x-hidden;
      & > .k-list {
        & > .k-item {
          @apply p-0 #{!important};
        }
      }
    }
  }
  .cc-search-header {
    @apply flex bg-secondary w-full;

    & > div {
      @apply p-2;
    }
  }
  .cc-search-item {
    @apply flex w-full;
    & > div {
      @apply p-2 break-words;
    }
  }
  .k-list-scroller,
  .cc-search-header {
    min-width: 800px;
  }
}
@media (max-width: 1000px) {
  .cc-location-picker-search {
    .k-popup {
      @apply py-0 overflow-x-auto #{!important};
      min-width: 250px;
    }
  }
}
